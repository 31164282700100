<template>
    <el-dialog
    v-model="dialogVisible"
    :title="type?'编辑信息':'添加信息'"
    width="40%"
    >
    <el-form
    ref="ruleFormRef"
    :model="ruleForm"
    :rules="rules"
    label-width="100px"
    size="large">
        <el-form-item label="子系统名称" prop="clientName">
            <el-input v-model="ruleForm.clientName" />
        </el-form-item>
        <el-form-item label="简称" prop="shorterName">
            <el-input v-model="ruleForm.shorterName" />
        </el-form-item>
        <el-form-item label="回调地址" prop="redirectUri">
            <el-input v-model="ruleForm.redirectUri" />
        </el-form-item>
        <el-form-item label="状态" prop="status">
            <el-radio-group v-model="ruleForm.status">
                <el-radio :label="1">启用</el-radio>
                <el-radio :label="0">禁用</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="描述" prop="description">
            <el-input type="textarea" v-model="ruleForm.description" />
        </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button color="rgb(128, 58, 21)" dark @click="formSubmit('ruleFormRef')">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { updataClient, addClient} from '@/api/app.js'
export default {
    emits:['OK'],
    data(){
        return{
            dialogVisible:false,
            type:0,
            ruleForm:{
                clientName:'',
                shorterName:'',
                redirectUri:'',
                description:'',
                status:1,
            },
            rules:{
                clientName:{ required: true, message: '子系统名称不能为空', trigger: 'blur' },
            }
            
            
        }
    },
    methods:{
        open(type,data){
            this.type = type
            this.dialogVisible = true
            if(type == 1){
                this.ruleForm = {
                    id:data.id,
                    clientName:data.clientName,
                    shorterName:data.shorterName,
                    redirectUri:data.redirectUri,
                    description:data.description,
                    status:data.status,
                    clientId:data.clientId,
                    clientSecret:data.clientSecret
                }
            }else{
                this.$nextTick(()=>{
                    this.$refs.ruleFormRef.resetFields() 
                })
            }
            
        },
        formSubmit(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loading = true
                    if(this.type == 0){
                        this.AddData(this.ruleForm)
                    }else{
                        this.EditData(this.ruleForm)
                    }
                } else {
                    return false;
                }
            });
        },
        AddData(data){
            addClient(data).then(r=>{
                this.loading = false
                if(r.code==200){
                    this.$message({
                        type: 'success',
                        message: '新增成功!'
                    });
                    this.$emit('OK',0)
                    this.dialogVisible = false
                }else{
                    this.$message({
                        type: 'warning',
                        message: '新增失败!'
                    });
                }
            }).catch(()=>{
                this.loading = false
            })
        },
        EditData(data){
            updataClient(data).then(r=>{
                this.loading = false
                if(r.code==200){
                    this.$message({
                        type: 'success',
                        message: '编辑成功!'
                    });
                    this.$emit('OK',1)
                    this.dialogVisible = false
                }else{
                    this.$message({
                        type: 'warning',
                        message: '编辑失败!'
                    });
                }
            }).catch(()=>{
                this.loading = false
            })
        },
    }
}
</script>