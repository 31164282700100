import request from '@/utils/request'
export function getData(params) {
  return request({
    url: '/client/listClient/2',
    method: 'get',
    params
  })
}
export function addClient(data) {
  return request({
    url: '/client/insertClient',
    method: 'post',
    data
  })
}
export function updataClient(data) {
  return request({
    url: '/client/updateClient',
    method: 'post',
    data
  })
}
export function delClient(id) {
  return request({
    url: `/client/deleteClient/${id}`,
    method: 'get',
  })
}
export function updataState(params) {
  return request({
    url: `/client/updateStatus`,
    method: 'get',
    params
  })
}