<template>
    <div class="pageBox">
        <div class="topBox">
            <el-button color="#803a15" dark size="large" @click="oper(0,null)">
                <svg-icon icon-class="plus"/>&nbsp;&nbsp;添加
            </el-button>
        </div>
        
        <div>
            <el-table 
            size="large" 
            :data="tableData" 
            style="width: 100%">
                <el-table-column prop="clientName" label="子系统名称" align="center" />
                <el-table-column prop="shorterName" label="简称"  align="center" />
                <el-table-column prop="clientId" label="客户端ID" align="center" />
                <el-table-column prop="clientSecret" label="密钥"  align="center" />
                <el-table-column prop="redirectUri" label="回调地址" align="center" />
                <el-table-column prop="description" label="描述" align="center" />
                <!-- <el-table-column prop="createUser" label="创建用户" align="center" /> -->
                <!-- <el-table-column prop="createTime" label="创建日期" align="center" /> -->
                <!-- <el-table-column prop="updateUser" label="更改用户" align="center" /> -->
                <!-- <el-table-column prop="updateTime" label="更新日期" align="center" /> -->
                <el-table-column label="状态" align="center">
                    <template #default="scope">
                        <el-switch
                        v-model="scope.row.status"
                        @change="changeStatu($event,scope.row)"
                        :inactive-value="0"
                        :active-value="1"
                        active-text="启用"
                        inactive-text="禁用"
                        />
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template #default="scope">
                        <el-button type="success" size="small" plain @click="oper(1,scope.row)">编辑</el-button>
                        <el-button type="danger" size="small" @click="del(scope.row.id)" plain>删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination 
            background 
            :page-size="query.size"
            :current-page="query.page"
            layout="total, prev, pager, next"
            @current-change="pageChange" 
            :total="total" />
        </div>
        <operForm ref="operForm" @OK="operOk"/>
    </div>
</template>
<script>
import operForm from './Modules/oper.vue'
import { getData, updataState, delClient } from '@/api/app.js'
export default {
    components:{
        operForm,
    },
    data(){
        return{
            query:{
                page: 1,
                size: 10,
            },
            total:0,
            tableData:[]
        }
    },
    mounted(){
        this.GetList()
    },
    methods:{
        GetList(){
            getData(this.query).then(r=>{
                if(r.code == 200){
                    this.tableData = r.data.lists.map(e => {
                        e.status = e.status!=null?e.status:0
                        return e
                    });
                    this.total = r.data.count
                }
                
            })
        },
        pageChange(page){
            this.query.page = page
            this.GetList()
        },
        oper(type,data){
            this.$refs.operForm.open(type,data)
        },
        del(id){
            this.$confirm(
                '此操作将永久删除该信息，是否继续?',
                'Warning',
                {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning',
                }
            ).then(() => {
                delClient(id).then(r=>{
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.query.page = 1
                    this.GetList()
                })
            })
            .catch(() => {
                
            })
        },
        changeStatu(e,data){
           this.$confirm(
                '此操作将会修改状态信息，是否继续?',
                'Warning',
                {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning',
                }
            ).then(() => {
                var para={
                    id:data.id,
                    status:e
                }
                updataState(para).then(r=>{
                    this.$message({
                        type: 'success',
                        message: '修改成功!'
                    });
                    this.GetList()
                })
            })
            .catch((err) => {
                if(e == 1){
                    data.status = 0
                }else{
                    data.status = 1
                }
            }) 
        },
        operOk(type){
            if(type == 0){
                this.query.page = 1
            }
            this.GetList()
        }
    }
}
</script>
<style lang="scss" scoped>
.topBox{
    width: 100%;
    margin-bottom: 20px;
}

.pageBox{
    width: 100%;
    padding: 20px 20px;
    box-sizing: border-box;
}
.el-pagination{
    margin-top: 20px;
    justify-content: center;
}

/deep/.el-pagination.is-background .el-pager li.is-active{
    background-color: #803a15;
}
</style>